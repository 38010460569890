import './styles/reset.scss';
import 'moment/locale/fr';

import { StylesProvider } from '@mui/styles';
import { LicenseInfo } from '@mui/x-date-pickers-pro';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import App from 'App';
import { MatomoContextProvider } from 'MatomoContextProvider';
import moment from 'moment';
import React from 'react';
import ReactDOM from 'react-dom';
import { isEhp } from 'utils';

import * as serviceWorker from './serviceWorker';

// TODO: move the key into a safe place
LicenseInfo.setLicenseKey(
  'e69b009f16b26a84032e03a34cc1c5b3Tz0xMDU5ODcsRT0xNzY4NTIxNTk5MDAwLFM9cHJvLExNPXN1YnNjcmlwdGlvbixQVj1RMy0yMDI0LEtWPTI='
);

Sentry.init({
  dsn: 'https://2be7b529b2a249dfb82d1c278e3bd662@sentry.prd.red/8',
  integrations: [new BrowserTracing()],
  environment: isEhp ? 'EHP' : process.env.NODE_ENV,
  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1,
});

moment.locale('fr');

ReactDOM.render(
  <React.StrictMode>
    <StylesProvider injectFirst>
      <MatomoContextProvider>
        <App />
      </MatomoContextProvider>
    </StylesProvider>
  </React.StrictMode>,
  document.querySelector('#root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
