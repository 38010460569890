import { Add } from '@mui/icons-material';
import LaunchIcon from '@mui/icons-material/Launch';
import { Box, Link } from '@mui/material';
import { AbsenceTable, AbsenceWarningTip } from 'components/AbsencePage';
import PageWrapper from 'components/commons/PageWrapper';
import TabHeader from 'components/commons/TabHeader';
import PongoButton from 'components/MUIOverload/PongoButton';
import { useEnvironmentVariable } from 'components/User/ConfigProvider';
import { useUserInfo } from 'components/User/UserProvider';
import AbsenceContextProvider, {
  useAbsenceContext,
} from 'contexts/Absence/AbsenceContextProvider';
import AbsenceDeclarationModal from 'pages/AbsenceDeclarationModal';
import { PageTitles } from 'poly-constants';
import React from 'react';

function AbsencePage() {
  return (
    <PageWrapper noLimit>
      <AbsenceContextProvider>
        <TabHeader
          title={PageTitles.absencePage}
          actionComponents={<AddAbsenceButton />}
          ignoreMissionHeader
        />
        <AbsenceWarningTip />
        <AbsenceTable />
        <AbsenceDeclarationModal />
      </AbsenceContextProvider>
    </PageWrapper>
  );
}

function AddAbsenceButton() {
  const { setModalOpen, setModalMode } = useAbsenceContext();
  const { isProvider } = useUserInfo();
  const LUCCA_FEATURE_FLAG = useEnvironmentVariable('lucca');
  const LUCCA_API_HOST = useEnvironmentVariable('LUCCA_API_HOST');
  const TIMMI_LINK = `${LUCCA_API_HOST}/timmi-absences/leave-request`;

  return (
    <>
      {LUCCA_FEATURE_FLAG && !isProvider ? (
        <Link href={TIMMI_LINK} target={'_blank'} rel={'noopener noreferrer'}>
          <PongoButton variant="contained" startIcon={<LaunchIcon />}>
            <Box sx={{ ml: 0.5, textTransform: 'uppercase' }}>
              Déclarer mes absences
            </Box>
          </PongoButton>
        </Link>
      ) : (
        <PongoButton
          variant="contained"
          startIcon={<Add />}
          onClick={() => {
            setModalMode('create');
            setModalOpen(true);
          }}
        >
          <Box sx={{ ml: 0.5, textTransform: 'uppercase' }}>
            Ajouter une absence
          </Box>
        </PongoButton>
      )}
    </>
  );
}

export default AbsencePage;
