import CloseIcon from '@mui/icons-material/Close';
import {
  Dialog,
  DialogContent,
  IconButton,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { AbsenceWarningTip } from 'components/AbsencePage';
import { useAbsenceContext } from 'contexts/Absence/AbsenceContextProvider';
import React from 'react';

import AbsenceDeclarationForm from './AbsenceDeclarationForm';

export default function AbsenceDeclarationModal() {
  const theme = useTheme();
  const {
    modalOpen: isOpen,
    setModalOpen,
    modalMode: mode,
    refetch,
    setAbsenceToViewOrEdit,
  } = useAbsenceContext();

  const onClose = () => {
    setModalOpen(false);
    setAbsenceToViewOrEdit(undefined);
  };
  const onSuccess = () => {
    refetch.current();
  };

  let modalTitle = '';
  switch (mode) {
    case 'create':
      modalTitle = 'Ajouter une absence';
      break;
    case 'edit':
      modalTitle = 'Modifier votre absence';
      break;
    default:
      modalTitle = 'Consulter votre absence';
  }

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth={false}>
      <DialogContent
        sx={{
          overflowX: 'hidden',
          width: '679px',
          backgroundColor: theme.palette.modalBackground.main,
          pt: 0,
        }}
        data-testid={'profile-modal'}
      >
        <Stack
          flexDirection={'row'}
          justifyContent={'space-between'}
          alignItems={'center'}
        >
          <Stack flexDirection={'row'} alignItems={'center'} sx={{ py: 2 }}>
            <Typography color="text.secondary" variant="h3Bold" sx={{ mr: 2 }}>
              {modalTitle}
            </Typography>
          </Stack>
          <IconButton edge={'end'} onClick={onClose} size="large">
            <CloseIcon />
          </IconButton>
        </Stack>
        <Stack
          sx={{
            backgroundColor: theme.palette.background.paper,
            p: 1.5,
            borderRadius: '8px',
          }}
        >
          <AbsenceWarningTip />
          <Typography variant="bodyBold" sx={{ my: 2 }}>
            Informations sur votre absence
          </Typography>
          <AbsenceDeclarationForm onClose={onClose} onSuccess={onSuccess} />
        </Stack>
      </DialogContent>
    </Dialog>
  );
}
