import { Link } from '@mui/material';
import PolyAlert from 'components/commons/PolyAlert';
import PolyAlertTitle from 'components/commons/PolyAlertTitle';
import { useEnvironmentVariable } from 'components/User/ConfigProvider';
import { useUserInfo } from 'components/User/UserProvider';
import React from 'react';

function AbsenceWarningTip(): JSX.Element {
  const { isProvider } = useUserInfo();
  const LUCCA_FEATURE_FLAG = useEnvironmentVariable('lucca');
  const LUCCA_API_HOST = useEnvironmentVariable('LUCCA_API_HOST');
  const TIMMI_LINK = `${LUCCA_API_HOST}/timmi-absences/leave-request`;
  if (isProvider) {
    return <></>;
  }
  return (
    <>
      {LUCCA_FEATURE_FLAG ? (
        <PolyAlert severity="info" variant="outlined" sx={{ mb: 2 }}>
          <PolyAlertTitle color="info">Information</PolyAlertTitle>
          La déclaration d’absence se fait désormais uniquement sur{' '}
          <Link
            sx={{ cursor: 'pointer', textDecoration: 'underline' }}
            href={TIMMI_LINK}
            target="_blank"
            rel="noopener"
          >
            Lucca
          </Link>
          . Vos absences seront automatiquement récupérées sur Pongo.
        </PolyAlert>
      ) : (
        <PolyAlert severity="warning" variant="outlined" sx={{ mb: 2 }}>
          <PolyAlertTitle color="warning">Attention ! </PolyAlertTitle>
          Assurez-vous d’avoir bien déclaré et validé vos congés sur{' '}
          <Link
            sx={{ cursor: 'pointer', textDecoration: 'underline' }}
            href="https://rh.polyconseil.fr/"
            target="_blank"
            rel="noopener"
          >
            le site RH
          </Link>{' '}
          avant de créer une absence ici.
        </PolyAlert>
      )}
    </>
  );
}

export default AbsenceWarningTip;
