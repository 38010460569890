import LaunchIcon from '@mui/icons-material/Launch';
import { Link } from '@mui/material';
import PongoButton from 'components/MUIOverload/PongoButton';
import { useEnvironmentVariable } from 'components/User/ConfigProvider';
import { useUserInfo } from 'components/User/UserProvider';
import React from 'react';

const DeclareAbsenceChip = () => {
  const { isProvider } = useUserInfo();
  const LUCCA_FEATURE_FLAG = useEnvironmentVariable('lucca');
  const LUCCA_API_HOST = useEnvironmentVariable('LUCCA_API_HOST');
  const ABSENCES_LINK = '/my-activity/absences';
  const TIMMI_LINK = `${LUCCA_API_HOST}/timmi-absences/leave-request`;

  return (
    <Link
      href={LUCCA_FEATURE_FLAG && !isProvider ? TIMMI_LINK : ABSENCES_LINK}
      target={'_blank'}
      rel={'noopener noreferrer'}
    >
      <PongoButton
        variant="contained"
        buttonStyle="secondary"
        color="primary"
        endIcon={<LaunchIcon />}
        uppercase={false}
        sx={{ height: '100%' }}
      >
        Déclarer mes absences
      </PongoButton>
    </Link>
  );
};

export default DeclareAbsenceChip;
