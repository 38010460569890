import { Delete } from '@mui/icons-material';
import {
  alpha,
  Box,
  Divider,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import PongoButton from 'components/MUIOverload/PongoButton';
import { useUserInfo } from 'components/User/UserProvider';
import { useAbsenceContext } from 'contexts/Absence/AbsenceContextProvider';
import {
  EmployeesEmployeeContractTypeChoices,
  EmployeeWeeklyContractNode,
  MeEmployeeFragment,
  PublicHoliday,
  useCreateAbsenceMutation,
  useDeleteAbsenceMutation,
  useFetchYearPublicHolidaysBorderingQuery,
  useUpdateAbsenceMutation,
} from 'generated/graphql';
import _ from 'lodash';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { WHIT_MONDAY_NAME } from 'poly-constants';
import React, { useState } from 'react';
import { graphQlDateFormatter } from 'utils';

/*
 ** Returns the number of working days for employee between two dates
 ** weeklyContracts : All weekly contracts for the employee ordered latest first
 ** start : Starting date of the period
 ** startMorning : true if the period starts in the morning
 ** end : Ending date of the period
 ** endAfternoon : true if the periods ends in the afternoon
 ** employee: the employee concerned
 ** holidays: list of holidays for year-1 to year+1 period
 */
const getNumberWorkingDaysForPeriod = (
  weeklyContracts: EmployeeWeeklyContractNode[],
  start: moment.Moment,
  startMorning: boolean,
  end: moment.Moment,
  endAfternoon: boolean,
  employee?: MeEmployeeFragment | null | undefined,
  holidays?: PublicHoliday[]
) => {
  let totalDays = 0;

  if (Math.abs(start.diff(end, 'day')) > 400) {
    return 0;
  }

  // Get the relevant weekly contracts reversed
  const usefulWeeklyContract = _.filter(weeklyContracts, (contract) => {
    const contractMoment = moment(contract.date);
    return (
      contractMoment.isSameOrBefore(end, 'week') &&
      contractMoment.isSameOrAfter(start, 'week')
    );
  });
  const firstWeeklyContract = _.find(weeklyContracts, (contract) => {
    const contractMoment = moment(contract.date);
    return contractMoment.isSameOrBefore(start, 'week');
  });
  if (firstWeeklyContract) {
    usefulWeeklyContract.push(firstWeeklyContract);
  }

  for (let m = start.clone(); m.isSameOrBefore(end, 'day'); m.add(1, 'day')) {
    const dayIsHoliday = _.find(
      holidays,
      (holiday) => holiday.date === m.format('YYYY-MM-DD')
    );
    if (dayIsHoliday) {
      const isIntern =
        employee?.contractType ===
          EmployeesEmployeeContractTypeChoices.Intern ||
        employee?.contractType ===
          EmployeesEmployeeContractTypeChoices.InternPreJob;
      if (dayIsHoliday.name !== WHIT_MONDAY_NAME || isIntern) {
        continue;
      }
    }
    const currentWeeklyContract = _.find(usefulWeeklyContract, (contract) => {
      const contractMoment = moment(contract.date);
      return contractMoment.isSameOrBefore(m, 'week');
    });
    if (!currentWeeklyContract) {
      continue;
    }
    const dayName = m
      .locale('en')
      .format('dddd')
      .toLowerCase() as keyof EmployeeWeeklyContractNode;

    if (!(m.isSame(start, 'day') && !startMorning)) {
      if (currentWeeklyContract[dayName][0]) {
        totalDays += 0.5;
      }
    }
    if (!(m.isSame(end, 'day') && !endAfternoon)) {
      if (currentWeeklyContract[dayName][1]) {
        totalDays += 0.5;
      }
    }
  }
  return totalDays;
};

export interface AbsenceDeclarationFormProps {
  onClose: () => void;
  onSuccess?: () => void;
}

export default function AbsenceDeclarationForm({
  onClose,
  onSuccess,
}: AbsenceDeclarationFormProps) {
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const { modalMode, absenceToViewOrEdit } = useAbsenceContext();
  const employeeWeeklyContracts = (useUserInfo().employee?.weeklyContracts ||
    []) as EmployeeWeeklyContractNode[];
  const [libelleValue, setLibelleValue] = useState<string>('Absence');
  const [startMorning, setstartMorning] = useState<boolean>(true);
  const [endAfternoon, setEndAfternoon] = useState<boolean>(true);
  const [startDate, setStartDate] = useState<moment.Moment>(moment());
  const [endDate, setEndDate] = useState<moment.Moment>(moment());

  const { data } = useFetchYearPublicHolidaysBorderingQuery({
    variables: { year: moment().year() },
  });

  const fillFormWithAbsenceToViewOrEdit = React.useCallback(() => {
    if (absenceToViewOrEdit) {
      setLibelleValue(absenceToViewOrEdit.label);
      setStartDate(moment(absenceToViewOrEdit.dateFrom));
      setEndDate(moment(absenceToViewOrEdit.dateTo));
      setstartMorning(!absenceToViewOrEdit.isStartHalfDay);
      setEndAfternoon(!absenceToViewOrEdit.isEndHalfDay);
    }
  }, [absenceToViewOrEdit]);

  const [createAbsence] = useCreateAbsenceMutation({
    onError: (error) => {
      enqueueSnackbar(error.message, {
        variant: 'error',
      });
    },
    onCompleted: () => {
      enqueueSnackbar('Absence enregistrée', {
        variant: 'success',
      });
      if (onSuccess) {
        onSuccess();
      }
    },
  });

  const [updateAbsence] = useUpdateAbsenceMutation({
    onError: (error) => {
      enqueueSnackbar(error.message, {
        variant: 'error',
      });
    },
    onCompleted: () => {
      enqueueSnackbar('Absence enregistrée', {
        variant: 'success',
      });
      if (onSuccess) {
        onSuccess();
      }
    },
  });

  const [deleteAbsence] = useDeleteAbsenceMutation({
    onError: (error) => {
      enqueueSnackbar(error.message, {
        variant: 'error',
      });
    },
    onCompleted: () => {
      enqueueSnackbar('Absence supprimée', {
        variant: 'success',
      });
      if (onSuccess) {
        onSuccess();
      }
    },
  });

  const numberDays = getNumberWorkingDaysForPeriod(
    employeeWeeklyContracts,
    startDate,
    startMorning,
    endDate,
    endAfternoon,
    useUserInfo().employee,
    data?.publicHolidaysBordering
  );

  const CreateAbsenceOnSave = () => {
    createAbsence({
      variables: {
        label: libelleValue,
        dateFrom: graphQlDateFormatter(startDate),
        isStartHalfDay: !startMorning,
        dateTo: graphQlDateFormatter(endDate),
        isEndHalfDay: !endAfternoon,
        numberOfDays: numberDays,
      },
    });
    onClose();
  };

  const UpdateAbsenceOnSave = () => {
    updateAbsence({
      variables: {
        id: absenceToViewOrEdit?.id || '',
        label: libelleValue,
        dateFrom: graphQlDateFormatter(startDate),
        isStartHalfDay: !startMorning,
        dateTo: graphQlDateFormatter(endDate),
        isEndHalfDay: !endAfternoon,
        numberOfDays: numberDays,
      },
    });
    onClose();
  };

  const DeleteAbsenceOnClick = () => {
    deleteAbsence({
      variables: {
        id: absenceToViewOrEdit?.id || '',
      },
    });
    onClose();
  };

  React.useEffect(() => {
    if (['view', 'edit'].includes(modalMode)) {
      fillFormWithAbsenceToViewOrEdit();
    }
  }, [fillFormWithAbsenceToViewOrEdit, modalMode]);

  return (
    <>
      <Box flexDirection="row" display="flex" align-items="center" gap={1}>
        <Box sx={{ maxHeight: '48px', width: '50%' }}>
          <TextField
            id="libelle"
            disabled={modalMode === 'view'}
            label="Libellé"
            variant="outlined"
            value={libelleValue}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setLibelleValue(event.target.value);
            }}
            sx={{ width: '100%' }}
          />
        </Box>
        <Box width="50%" p={0.5} />
      </Box>
      <Divider sx={{ mt: 3 }} />
      <Typography variant="bodyBold" sx={{ mt: 2 }}>
        Date
      </Typography>
      <Box
        sx={{ mt: 2, maxHeight: '48px' }}
        flexDirection="row"
        display="flex"
        alignItems="center"
        gap={1}
      >
        <Box sx={{ width: '50%' }}>
          <DatePicker
            value={startDate}
            disabled={modalMode === 'view'}
            onChange={(newValue) => {
              const momentNewValue = moment(newValue);
              setStartDate(momentNewValue);
              if (momentNewValue > endDate) {
                setEndDate(momentNewValue);
              }
            }}
            renderInput={(props) => (
              <TextField sx={{ width: '100%' }} {...props} label="Du" />
            )}
          />
        </Box>
        <Box
          sx={{
            width: '50%',
            height: '100%',
            backgroundColor: theme.palette.modalBackground.main,
            p: 0.5,
            borderRadius: 1,
          }}
          flexDirection="row"
          display="flex"
          align-items="center"
        >
          <PongoButton
            disabled={modalMode === 'view'}
            sx={{
              width: '50%',
              textTransform: 'capitalize',
              color: !startMorning
                ? theme.palette.standardGrey.light
                : theme.palette.common.white,
              fontWeight: !startMorning ? 400 : 700,
              borderRadius: 1,
            }}
            variant={startMorning ? 'contained' : 'text'}
            onClick={() => {
              setstartMorning(true);
            }}
          >
            Matin
          </PongoButton>
          <PongoButton
            disabled={modalMode === 'view'}
            sx={{
              width: '50%',
              textTransform: 'capitalize',
              color: startMorning
                ? theme.palette.standardGrey.light
                : theme.palette.common.white,
              fontWeight: startMorning ? 400 : 700,
              borderRadius: 1,
            }}
            variant={!startMorning ? 'contained' : 'text'}
            onClick={() => {
              setstartMorning(false);
            }}
          >
            Après-midi
          </PongoButton>
        </Box>
      </Box>
      <Box
        flexDirection="row"
        display="flex"
        alignItems="center"
        gap={1}
        sx={{ mt: 3, maxHeight: '48px' }}
      >
        <Box sx={{ width: '100%' }}>
          <DatePicker
            value={endDate}
            disabled={modalMode === 'view'}
            onChange={(newValue) => {
              const momentNewValue = moment(newValue);
              setEndDate(momentNewValue);
              if (momentNewValue < startDate) {
                setStartDate(momentNewValue);
              }
            }}
            renderInput={(props) => (
              <TextField sx={{ width: '100%' }} {...props} label="Au" />
            )}
          />
        </Box>
        <Box
          sx={{
            width: '100%',
            height: '100%',
            backgroundColor: theme.palette.modalBackground.main,
            p: 0.5,
            borderRadius: 1,
          }}
          display="flex"
          flexDirection="row"
          align-items="center"
        >
          <PongoButton
            disabled={modalMode === 'view'}
            sx={{
              width: '50%',
              textTransform: 'capitalize',
              color: endAfternoon
                ? theme.palette.standardGrey.light
                : theme.palette.common.white,
              fontWeight: endAfternoon ? 400 : 700,
              borderRadius: 1,
            }}
            variant={!endAfternoon ? 'contained' : 'text'}
            onClick={() => {
              setEndAfternoon(false);
            }}
          >
            Matin
          </PongoButton>
          <PongoButton
            disabled={modalMode === 'view'}
            sx={{
              width: '50%',
              textTransform: 'capitalize',
              color: !endAfternoon
                ? theme.palette.standardGrey.light
                : theme.palette.common.white,
              fontWeight: !endAfternoon ? 400 : 700,
              borderRadius: 1,
            }}
            variant={endAfternoon ? 'contained' : 'text'}
            onClick={() => {
              setEndAfternoon(true);
            }}
          >
            Après-midi
          </PongoButton>
        </Box>
      </Box>
      <Divider sx={{ mt: 3 }} />
      <Box sx={{ mt: 2 }} flexDirection="row" display="flex">
        <Typography variant="bodyBold">
          Jours d&apos;absences comptabilisés
        </Typography>
        <Typography
          variant="bodyBold"
          sx={{ ml: 1, color: theme.palette.primary.main }}
        >
          {numberDays}
        </Typography>
      </Box>
      {modalMode !== 'view' && (
        <>
          <Divider sx={{ mt: 2 }} />
          <Box
            sx={{ mt: 2 }}
            flexDirection="row"
            justifyContent="flex-end"
            display="flex"
          >
            {modalMode === 'edit' && (
              <DeleteAbsenceButton onClick={DeleteAbsenceOnClick} />
            )}
            <PongoButton
              variant="contained"
              buttonStyle="secondary"
              onClick={onClose}
              sx={{ mr: 1 }}
            >
              Annuler
            </PongoButton>
            <PongoButton
              variant="contained"
              buttonStyle="primary"
              disabled={numberDays <= 0}
              onClick={() => {
                if (modalMode === 'create') {
                  CreateAbsenceOnSave();
                } else {
                  UpdateAbsenceOnSave();
                }
              }}
            >
              Enregistrer
            </PongoButton>
          </Box>
        </>
      )}
    </>
  );
}

function DeleteAbsenceButton({ onClick }: { onClick: () => void }) {
  const theme = useTheme();
  return (
    <PongoButton
      onClick={onClick}
      sx={{
        mr: 'auto',
        backgroundColor: alpha(theme.palette.error.main, 0.25),
        color: theme.palette.error.main,
        px: 2,
        '&:hover': {
          backgroundColor: theme.palette.error.main,
          color: theme.palette.error.contrastText,
        },
      }}
      startIcon={<Delete />}
    >
      Supprimer
    </PongoButton>
  );
}
