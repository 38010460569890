import { AbsenceNode } from 'generated/graphql';
import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
} from 'react';

export interface AbsenceContextProps {
  modalMode: 'view' | 'create' | 'edit';
  setModalMode: Dispatch<SetStateAction<'view' | 'create' | 'edit'>>;
  modalOpen: boolean;
  setModalOpen: Dispatch<SetStateAction<boolean>>;
  absenceToViewOrEdit?: AbsenceNode;
  setAbsenceToViewOrEdit: Dispatch<SetStateAction<AbsenceNode | undefined>>;
  refetch: React.MutableRefObject<() => void>;
}

const AbsenceContext = createContext<AbsenceContextProps>({
  modalMode: 'create',
  setModalMode: () => {
    throw new Error('setModalMode was not implemented');
  },
  modalOpen: false,
  setModalOpen: () => {
    throw new Error('setModalOpen was not implemented');
  },
  setAbsenceToViewOrEdit: () => {
    throw new Error('setAbsenceToViewOrEdit was not implemented');
  },
  refetch: { current: () => void {} },
});

export const useAbsenceContext = () => {
  const context = useContext(AbsenceContext);
  if (!context) {
    throw new Error(
      'useAbsenceContext was used outside of AbsenceContextProvider'
    );
  }
  return context;
};

interface AbsenceContextProviderProps {
  children: React.ReactFragment;
}

export default function AbsenceContextProvider({
  children,
}: AbsenceContextProviderProps) {
  const [modalMode, setModalMode] = React.useState<'view' | 'create' | 'edit'>(
    'view'
  );
  const [modalOpen, setModalOpen] = React.useState(false);
  const [absenceToViewOrEdit, setAbsenceToViewOrEdit] = React.useState<
    AbsenceNode | undefined
  >(undefined);
  const refetch = React.useRef<() => void>(() => void {});

  return (
    <AbsenceContext.Provider
      value={{
        modalMode,
        setModalMode,
        modalOpen,
        setModalOpen,
        absenceToViewOrEdit,
        setAbsenceToViewOrEdit,
        refetch,
      }}
    >
      {children}
    </AbsenceContext.Provider>
  );
}
